import React from 'react'

import './antd/less/antMotionStyle.less'
import { Footer10DataSource } from './antd/data.source'
import Footer from './antd/Footer'
import HeadNav from './antd/HeadNav'
import { enquireScreen } from 'enquire-js'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Layout extends React.Component {
  constructor(...props) {
    super(...props)
    this.state = {
      isMobile,
      hasBg: false,
    }
  }

  bindHandleScroll = event => {
    const scrollTop =
      (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
      window.pageYOffset ||
      (event.srcElement ? event.srcElement.body.scrollTop : 0)
    if (scrollTop > 200) {
      this.setState({ hasBg: true })
    } else {
      this.setState({ hasBg: false })
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
    if (!this.state.isMobile) {
      window.addEventListener('scroll', this.bindHandleScroll)
    }
  }

  componentWillUnmount() {
    if (!this.state.isMobile) {
      window.removeEventListener('scroll', this.bindHandleScroll)
    }
  }

  render() {
    const {
      children,
      products,
      productGroups,
      showFooter = false,
      curIndex = -1,
      hasBg = false,
    } = this.props

    return (
      <div className='templates-wrapper'>
        <HeadNav
          id='HeadNav_0'
          key='HeadNav_0'
          curIndex={curIndex}
          products={products}
          hasBg={this.state.isMobile ? true : this.state.hasBg || hasBg}
          productGroups={productGroups}
          isMobile={this.state.isMobile}
        />
        {children}
        {showFooter ? (
          <Footer
            id='Footer1_0'
            key='Footer1_0'
            dataSource={Footer10DataSource}
            isMobile={this.state.isMobile}
          />
        ) : null}
      </div>
    )
  }
}
