import React from 'react'

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page head-con' },
  logo: {
    className: 'header0-logo',
    children: 'logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: <p>首页</p>,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>核心技术</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: <p>核心技术</p>,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>解决方案</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/news',
          children: [
            {
              children: <p>动态</p>,
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>开发者</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item6',
        className: 'header0-item',
        children: {
          href: '/contact',
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
}
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: (
      <span>
        <p>茶纹云</p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content',
    children: (
      <span>
        <span>
          <span>
            <p>
              一个基于人工智能+区块链领先技术，服务于茶行业的溯源及数据资产管理平台，其领先理念可推广及推动可信农业的发展。
            </p>
          </span>
        </span>
      </span>
    ),
  },
  button: { className: 'banner0-button', children: '了解更多' },
}
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>服务功能</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>可信溯源</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    提供基于人工智能+区块链的三级可信溯源技术
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>可信营销</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>打造基于可信溯源的互联网交易支撑平台</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '一站式数据运营',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <a
                      href='https://tea-leaf.chawenyun.com/management/#/dataAnalysis'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      提供一站式平台、企业数据资产管理
                    </a>
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Teams10DataSource = {
  wrapper: { className: 'home-page-wrapper teams1-wrapper' },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>
              深入了解
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://oss.chawenyun.com/tea-shop/home/WechatIMG618.jpeg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>公众号</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>
                    “茶纹云”官方公众号
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    “茶纹云”官方公众号提供本平台各种技术信息及动态
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://oss.chawenyun.com/tea-shop/home/gh_d40e481084b4_258.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>可信溯源应用</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>“易见纹之语”小程序</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    “易见纹之语”小程序是基于“茶纹云”平台的普洱茶溯源应用。方便茶企及消费者进行移动端的产品溯源及资产管理。
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://oss.chawenyun.com/tea-shop/home/gh_77d2187b5099_258.jpg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>可信营销应用</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job',
              children: (
                <span>
                  <p>“茶城1号”小程序</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: (
                <span>
                  <p>
                    “茶城1号”是基于“茶纹云”平台的可信营销应用。由“茶纹云”提供技术实现，并由茶企进行业务运营。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children: (
            <span>
              <p>茶纹云</p>
            </span>
          ),
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>领先的溯源技术及数字资产管理平台</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>平台产品</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://oss.chawenyun.com/tea-shop/home/易见茶纹溯源.pdf',
              children: (
                <span>
                  <p>产品介绍手册</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p>
                    使用指南
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <span>
                    <br />
                  </span>
                </span>
              ),
            },
            {
              name: 'link3',
              href: '#',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
            },
            { href: '#', name: 'link1', children: '联系我们' },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '资源' },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>介绍视频</p>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link1',
              children: (
                <span>
                  <br />
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <p>增值电信业务经营许可证：滇B2-20200061</p>
        <p>©2019 云南易见纹语科技有限公司版权所有</p>
        <div className='keep-on-record'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53011202000908'
          >
            <img src='https://oss-public.chawenyun.com/beian.png' alt='备案' />
            <p>滇公网安备 53011202000908号</p>
            <p>滇网信备 53011120765669640011号</p>
          </a>
        </div>
      </span>
    ),
  },
}
export const Contact01DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: {
        children:
          'https://gaode.com/search?id=B0FFKMJMSU&city=110108&geoobj=116.213024%7C39.799562%7C116.707409%7C40.015513&query_type=IDQ&query=%E4%B8%AD%E5%85%B3%E6%9D%91%E5%A3%B9%E5%8F%B7A2%E5%BA%A7&zoom=12',
        name: '跳转地址',
      },
      title: { children: '公司地址', name: '弹框标题' },
      content: {
        children: '纹语科技 中关村壹号A2座',
        name: '弹框内容',
      },
    },
  },
}
export const Banner11DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    autoPlay: true,
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1 kknedj4fvk-editor_css' },
        title: {
          className: 'banner1-title',
          children:
            'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
    className: 'banner-anim',
  },
}
