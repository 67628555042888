import React from 'react'
import logoUrl from './../../images/logo.png'
import logoUrl2 from './../../images/logo2.png'
import { navigate } from 'gatsby'

class HeadNav extends React.PureComponent {
  constructor(...props) {
    super(...props)
    this.state = {
      isSpread: false, // 手机端menu是否展开
      headerHover: false,
    }
  }

  changeSpread = () => {
    const isSpread = !this.state.isSpread
    this.setState({ isSpread })
  }

  mouseIn = () => {
    this.setState({
      headerHover: true,
    })
  }

  mouseOut = () => {
    this.setState({
      headerHover: false,
    })
  }

  render() {
    const { isSpread, headerHover } = this.state
    const {
      curIndex = 0,
      isMobile,
      /* products = [],
      productGroups = [], */
      hasBg = false,
    } = this.props

    /* const softwareProducts = products.filter(
      product => product.type === "software"
    )
    const hardwareProducts = products.filter(
      product => product.type === "hardware"
    )
    const softwareProductGroups = productGroups.filter(
      group => group.type === "software"
    ) */
    return (
      <div
        className={hasBg ? 'head-wrap bgcolor' : 'head-wrap'}
        onMouseOver={this.mouseIn}
        onMouseLeave={this.mouseOut}
      >
        <div className='head-con'>
          <a href='/'>
            <img
              className='logo'
              src={headerHover || hasBg ? logoUrl2 : logoUrl}
              alt='易见纹语科技白色logo'
            />
          </a>
          <div
            className={
              isMobile ? 'head-mobile-menu-icon show' : 'head-mobile-menu-icon'
            }
            onClick={this.changeSpread}
          >
            <span className={isSpread ? 'first-rotate' : ''}></span>
            <span className={isSpread ? 'mid-width' : ''}></span>
            <span className={isSpread ? 'last-rotate' : ''}></span>
          </div>
          <ul className={isSpread ? 'nav-list spread' : 'nav-list'}>
            <li
              className={curIndex === 0 ? 'list-item cur' : 'list-item'}
              onClick={() => navigate('/')}
            >
              首页
            </li>
            <li className={curIndex === 1 ? 'list-item cur' : 'list-item'}>
              核心技术
              <ol className='sub-menu'>
                <li
                  onClick={() =>
                    navigate('/core-technology/texture-recognition')
                  }
                >
                  区块链+AI溯源技术
                </li>
                <li onClick={() => navigate('/core-technology/trace-source')}>
                  物体纹理识别
                </li>
                <li
                  onClick={() =>
                    navigate('/core-technology/plant-insect-pests-analysis')
                  }
                >
                  植物病虫害分析
                </li>
                <li
                  onClick={() =>
                    navigate('/core-technology/depth-video-analysis')
                  }
                >
                  深度视频分析
                </li>
                {/* <li
                  onClick={() =>
                    navigate("/core-technology/intelligent-hardware")
                  }
                >
                  智能硬件
                </li> */}
              </ol>
            </li>
            <li
              className={
                curIndex === 2 ? 'list-item product cur' : 'list-item product'
              }
            >
              {/* <p>产品</p>
              <div className="product-sub">
                <dl className="hard-product">
                  <dt>硬件产品</dt>
                  {hardwareProducts
                    .filter(product => !product.product_group)
                    .map(product => (
                      <dd
                        key={product.id}
                        onClick={() => navigate(`/products/${product.id}`)}
                      >
                        {product.title}
                      </dd>
                    ))}
                </dl>
                <dl className="soft-product">
                  <dt>智能硬件产品</dt>
                  {softwareProductGroups.map(group => {
                    const groupProducts = softwareProducts.filter(
                      product =>
                        product.product_group &&
                        product.product_group.code === group.code
                    )
                    if (groupProducts.length > 0) {
                      return (
                        <dd key={`group-${group.id}`}>
                          <p>{group.name}</p>
                          <ol>
                            {groupProducts.map(product => (
                              <li
                                key={`product-${product.id}`}
                                onClick={() =>
                                  navigate(`/products/${product.id}`)
                                }
                              >
                                {product.title}
                              </li>
                            ))}
                          </ol>
                        </dd>
                      )
                    }
                    return null
                  })}
                </dl>
              </div> */}
              产品
              <div>
                <div className='sub-menu'>
                  <div className='technology-sub-box'>
                    <div className='technology-sub-title'>
                      <div className='technology-sub-title-text'>
                        “数耘”技术平台
                      </div>

                      <ol className='technology-sub-content'>
                        <li /* onClick={() => navigate(`/product/digitizing`)} */
                        >
                          全链条数字化产品服务
                        </li>
                        <li /* onClick={() => navigate(`/product/traceability`)} */
                        >
                          溯源产品服务
                        </li>
                        <li
                        /* onClick={() =>
                            navigate(`/product/online-transaction`)
                          } */
                        >
                          线上交易产品服务
                        </li>
                        <li /* onClick={() => navigate(`/product/supply-chain`)} */
                        >
                          供应链金融支撑产品服务
                        </li>
                      </ol>
                    </div>
                    <div
                      className='technology-sub-title'
                      onClick={() => navigate(`/product/hardware`)}
                    >
                      <div className='technology-sub-title-text'>
                        智能硬件产品
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li
              className={
                curIndex === 3 ? 'list-item solution cur' : 'list-item solution'
              }
              /*  onClick={() => navigate('/solution-list')} */
            >
              产业数字化平台
              <ol className='sub-menu'>
                <li
                  onClick={() => navigate('/solution-list/tea-pattern-cloud')}
                >
                  茶纹云-茶叶产业平台
                </li>
                <li onClick={() => navigate('/solution-list/glue-plowing')}>
                  胶耘-数字橡胶产业平台
                </li>
                <li onClick={() => navigate('/solution-list/marijuana')}>
                  工麻云-工业大麻溯源平台
                </li>
              </ol>
            </li>
            <li
              className={curIndex === 4 ? 'list-item cur' : 'list-item'}
              onClick={() => navigate('/honor')}
            >
              荣誉
            </li>
            <li className={curIndex === 5 ? 'list-item cur' : 'list-item'}>
              关于我们
              <ol className='sub-menu'>
                <li onClick={() => navigate('/company-intro')}>公司简介</li>
                <li onClick={() => navigate('/news')}>新闻动态</li>
                <li onClick={() => navigate('/team-intro')}>团队介绍</li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default HeadNav
