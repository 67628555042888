import React from 'react'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import QueueAnim from 'rc-queue-anim'
import logo from './../../images/logo.png'
import shugeng from './../../images/shugeng.jpg'
import chawenyun from './../../images/chawenyun.jpg'

class Footer extends React.Component {
  render() {
    return (
      <div className='footer-wrapper'>
        <OverPack className='footer' playScale={0.1}>
          <QueueAnim type='bottom' leaveReverse className='main-con'>
            <div>
              <img src={logo} alt='logo' />
            </div>
            <div className='footer-list'>
              <dl>
                <dt>地址</dt>
                <dd>云南省昆明市西山区前卫西路688号易见股份</dd>
                <dd>北京市海淀区西北旺镇北清路中关村1号A2座</dd>
              </dl>
              <dl>
                <dt>联系我们</dt>
                <dd>售前咨询：0871-65739748-7066</dd>
                <dd>商务合作：business@v-origin.com</dd>
                <dd>商务合作：business@v-origin.com</dd>
              </dl>
              <dl>
                <dt>招聘</dt>
                <dd>社会招聘</dd>
                <dd>
                  <div className='imgs'>
                    <img src={shugeng} alt='数耕' />
                    <img src={chawenyun} alt='茶纹云' />
                  </div>
                  <p className='tip-text'>扫码关注公众号</p>
                </dd>
              </dl>
            </div>
          </QueueAnim>
        </OverPack>
        <div className='copyright'>
          <p>
            增值电信业务经营许可证：滇B2-20200061 ©2021
            云南易见纹语科技有限公司版权所有
          </p>
          <p>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=53011202000908'
            >
              <img
                src='https://oss-public.chawenyun.com/beian.png'
                alt='备案'
              />
              滇公网安备 53011202000908号 滇网信备 53011120765669640011号
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default Footer
